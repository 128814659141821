define('bootstraps/enhanced/media/video-player',[
    'videojs',
    'videojsads',
    'videojsima',
    'videojspersistvolume',
    'videojsplaylist',
    'videojsembed'
], function (video) {
    return video;
});

